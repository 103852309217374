import "../components/base.css";
import Layout from "../components/layout";
import React from "react";


export default class Index extends React.Component {
  render() {
    return (
      <Layout>
        <div>
          <br></br>
          <h1>&gt; BUYING GUIDE</h1>
        </div>
        <br></br>
        <div>
          <h2>GRAY MARKET SALES /</h2>
          <h3>Should I buy ships, upgrades, ccus, anything from a grey market (Reddit, Star-Hangar.com, E-Bay)?</h3>
            <h4><p>Unless you can't wait 6 months the short answer is: NO.</p><p>Always spend your money on <a rel="noreferrer" target="_blank" href="https://robertsspaceindustries.com/pledge/">the official pledge store.</a></p></h4>
          <br></br>
        </div>
      </Layout>
    );
  }
}